<template>

	<!-- Layout Header ( Navbar ) -->
	<a-layout-header>
		<div class="header-col header-brand">
      <router-link to="/" class="nav-link">
			<h6>FOLIB漏洞库</h6>
      </router-link>
			<!-- Trigger Button For Navigation Menu For Small Screens -->
			<a-button type="link" @click="collapseNav = collapseNav ? 0 : 1 " class="btn-menu-trigger">
				<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"/></svg>
			</a-button>
			<!-- Trigger Button For Navigation Menu For Small Screens -->

		</div>
		<div class="header-col header-nav">
			<!-- Navigation Menu For Large Screens -->
			<a-menu mode="horizontal" class="menu-large">
				<a-sub-menu>
					<span slot="title" class="submenu-title-wrapper">
						<a-icon type="alert" theme="filled" class="m-0" />
            <a href="https://nvd.folib.com/">
						<span >FOLIB</span>
            </a>
					</span>
				</a-sub-menu>
				<a-sub-menu>
					<span slot="title" class="submenu-title-wrapper">
						<a-icon type="security-scan" theme="filled" class="m-0" />
            <a href="https://www.cnnvd.org.cn/home/childHome">
						<span>CNVD</span>
            </a>
					</span>
				</a-sub-menu>
				<a-sub-menu>
					<span slot="title" class="submenu-title-wrapper">
						<a-icon type="bug" theme="filled" class="m-0" />
            <a href="https://nvd.nist.gov/vuln">
						<span>NVD</span>
            </a>
					</span>
				</a-sub-menu>
				<a-sub-menu>
					<span slot="title" class="submenu-title-wrapper">
						<a-icon type="cloud" theme="filled" class="m-0" />
            <a href="https://www.bocloud.com.cn/">
						<span>数据申请</span>
            </a>
					</span>
				</a-sub-menu>
			</a-menu>

		</div>
		<div class="header-col header-btn">
			<a-button size="small" type="dark" class="px-30 border-dark" shape="round" @click="openViewDialog">离线数据</a-button>
		</div>

    <a-drawer
        placement="right"
        width="45%"
        title="离线数据下载或上传"
        :visible="viewVisible"
        @close="closeViewDialog"
    >

      <a-tabs
          class="tabs-sliding"
          :default-active-key="1"
          @change=""
      >
        <a-tab-pane :key="1" tab="数据列表">
          <p>提示信息：</p>
          <ul class="pl-15 text-muted">
            <li>本数据风格保持与NVD一致，并且包含了CNNVD相关数据和标签</li>
            <li>如果本漏洞库已经可以访问公网，无需离线更新，每天4次定时从nvd.folib.com中进行下载更新</li>
            <li><strong>请下载后再私有部署的漏洞库页面上选择离线上传</strong></li>
          </ul>
          <a-list
              class="invoice-list"
              item-layout="horizontal"
              :split="false"
              :data-source="gzData"
          >
            <a-list-item slot="renderItem" slot-scope="item">
              <a-button slot="actions" type="link" @click="handleDownloadFile(item.gzName)">
                <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3 17C3 16.4477 3.44772 16 4 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H4C3.44772 18 3 17.5523 3 17ZM6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L9 10.5858L9 3C9 2.44772 9.44771 2 10 2C10.5523 2 11 2.44771 11 3L11 10.5858L12.2929 9.29289C12.6834 8.90237 13.3166 8.90237 13.7071 9.29289C14.0976 9.68342 14.0976 10.3166 13.7071 10.7071L10.7071 13.7071C10.5196 13.8946 10.2652 14 10 14C9.73478 14 9.48043 13.8946 9.29289 13.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289Z" fill="#111827"/>
                </svg>
                GZ
              </a-button>
              <a-list-item-meta
                  :title="item.gzName"
                  :description="item.modifiedDate"
              ></a-list-item-meta>
              <div class="amount">{{ item.gzSize }}</div>
            </a-list-item>
          </a-list>

        </a-tab-pane>
        <a-tab-pane :key="2" tab="离线更新">

          <a-form
              :form="form"
              :hideRequiredMark="true"
          >
            <a-row :gutter="[24]">
              <a-col :span="24">
                <a-form-item class="mb-10" label="漏洞数据离线上传" :colon="false">
                  <p>提示信息：</p>
                  <ul class="pl-15 text-muted">
                    <li>本数据风格保持与NVD一致，并且包含了CNNVD相关数据和标签</li>
                    <li>如果本漏洞库已经可以访问公网，无需离线更新，每天4次定时从nvd.folib.com中进行下载更新</li>
                    <li><strong>请下载后再私有部署的漏洞库页面上选择离线上传</strong></li>
                  </ul>
                  <a-upload-dragger
                      name="avatar"
                      list-type="picture"
                      class="projects-uploader"
                      :showUploadList="true"
                      :multiple="true"
                      :file-list="fileList"
                      :custom-request="handleUpload"
                      :before-upload="beforeUpload"
                      @change="handleChange"
                      style="height: 150px;"
                  >
                    <img v-if="false" src="" alt="avatar" />
                    <div v-else>
                        <p class="ant-upload-drag-icon">
                            <a-icon type="upload" />
                        </p>
                      <div class="ant-upload-text font-regular text-muted">
                        选择你要上传的文件，文件格式为nvdcve-1.1-年份.json.gz
                      </div>
                    </div>
                  </a-upload-dragger>
                </a-form-item>
              </a-col>
            </a-row>
<!--            <a-row :gutter="[24]">-->
<!--              <a-col :span="12">-->
<!--              </a-col>-->
<!--              <a-col :span="12" class="text-right">-->
<!--                <a-button type="primary"  class="px-25">上传</a-button>-->
<!--              </a-col>-->
<!--            </a-row>-->
          </a-form>

        </a-tab-pane>
<!--        <a-button v-if="eventSettingEnabled" slot="tabBarExtraContent" class="repository-setting ant-btn ant-btn-sm ant-btn-icon-only" size="small" @click="eventPageVisible = true" >-->
<!--          <i aria-label="icon: setting" class="anticon anticon-setting">-->
<!--            <svg t="1703130640254" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4432" width="1em" height="1em"><path d="M722.189474 792.252632c-10.778947 0-18.863158 8.084211-18.863158 18.863157v115.873685c0 13.473684-10.778947 24.252632-24.252632 24.252631H97.010526c-13.473684 0-24.252632-10.778947-24.252631-24.252631V344.926316c0-13.473684 10.778947-24.252632 24.252631-24.252632h582.063158c13.473684 0 24.252632 10.778947 24.252632 24.252632V646.736842c0 10.778947 8.084211 18.863158 18.863158 18.863158s18.863158-8.084211 18.863158-18.863158V344.926316c0-35.031579-26.947368-61.978947-61.978948-61.978948H97.010526c-35.031579 0-61.978947 26.947368-61.978947 61.978948v582.063158c0 35.031579 26.947368 61.978947 61.978947 61.978947h582.063158c35.031579 0 61.978947-26.947368 61.978948-61.978947v-115.873685c0-10.778947-8.084211-18.863158-18.863158-18.863157z" fill="#101010" p-id="4433"></path><path d="M926.989474 35.031579H344.926316c-35.031579 0-61.978947 26.947368-61.978948 61.978947v123.957895c0 10.778947 8.084211 18.863158 18.863158 18.863158s18.863158-8.084211 18.863158-18.863158V97.010526c0-13.473684 10.778947-24.252632 24.252632-24.252631h582.063158c13.473684 0 24.252632 10.778947 24.252631 24.252631v582.063158c0 13.473684-10.778947 24.252632-24.252631 24.252632H344.926316c-13.473684 0-24.252632-10.778947-24.252632-24.252632V388.042105c0-10.778947-8.084211-18.863158-18.863158-18.863158s-18.863158 8.084211-18.863158 18.863158v291.031579c0 35.031579 26.947368 61.978947 61.978948 61.978948h582.063158c35.031579 0 61.978947-26.947368 61.978947-61.978948V97.010526c0-35.031579-26.947368-61.978947-61.978947-61.978947z" fill="#101010" p-id="4434"></path></svg>-->
<!--          </i>-->
<!--        </a-button>-->
<!--        <a-button v-if="settingsEnabled" slot="tabBarExtraContent" icon="setting" class="repository-setting" size="small" @click="settingDrawerShow()" />-->
      </a-tabs>


    </a-drawer>
	</a-layout-header>
	<!-- / Layout Header ( Navbar ) -->



</template>

<script>

import {feedsPaginQuery, uploadFiles,downloadFile} from "@/api/vulnerabilities";

  export default ({
		data() {
			return {
        fileList: [],
        form:{},
				// Collapse navigation value.
				// Binded model property for "Collapsible Navigation Menu" collapsed status .
				collapseNav: 0,
				// Sidebar collapsed status.
				sidebarCollapsed: {
					type: Boolean,
					default: false,
				},

        gzData:[],
				// Main sidebar color.
				sidebarColor: {
					type: String,
					default: "primary",
				},
        viewVisible: false,
				
				// Main sidebar theme : light, white, dark.
				sidebarTheme: {
					type: String,
					default: "light",
				},
      			rootSubmenuKeys: ['dashboards', 'pages', 'applications', 'ecommerce', 'authentication', 'basic', 'components', 'changelog'],
				openKeys: null,
			}
		},
      mounted() {
          document.addEventListener('click', this.handleOutsideClick);
      },
      beforeUnmount() {
          document.removeEventListener('click', this.handleOutsideClick);
      },
		methods: {
      closeViewDialog(){
          this.viewVisible=false;
          this.fileList=[]
      },
      openViewDialog(){
        this.viewVisible=true
        feedsPaginQuery().then((res) => {
          this.gzData=res.data.data
        })
      },
			onOpenChange(openKeys)
			{
				this.openKeys = this.openKeys ? this.openKeys : this.$route.meta.sidebarMap ;
				
				const latestOpenKey = openKeys.find( key => this.openKeys.indexOf( key ) === -1) ;

				if ( this.rootSubmenuKeys.indexOf( latestOpenKey ) === -1 )
				{
					this.openKeys = openKeys;
				}
				else
				{
					this.openKeys = latestOpenKey ? [ latestOpenKey ] : [] ;
				}
			},
        handleDownloadFile(fileName) {
            downloadFile(fileName).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName); // 更改文件名和扩展名
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        },
        beforeUpload(file) {
            // 只允许上传 .gz 文件
            const fileName = file.name;
            const fileExtension = fileName.split('.').pop().toLowerCase();
            if (fileExtension !== 'gz') {
                this.$message.error('Only .gz files are allowed.');
                return false;
            }
            // 正则表达式检查文件名格式: nvdcve-1.1-年份.json.gz
            const regex = /^nvdcve-1\.1-\d{4}\.json\.gz$/;
            if (!regex.test(fileName)) {
                this.$message.error('File name must be in the format nvdcve-1.1-YYYY.json.gz.');
                return false;
            }
            return true; // 返回 false 会阻止上传
        },
        handleChange(info) {
            // 更新文件列表
            this.fileList = info.fileList
            if (info.file.status === 'done') {
                this.$message.success(`${info.file.name} file uploaded success.`);
            } else if (info.file.status === 'error') {
                this.$message.error(`${info.file.name} file upload failed.`);
            }
        },
        async handleUpload({ file, onProgress, onSuccess, onError }) {
            const files = this.fileList;
            // 模拟异步上传操作，可以使用 axios 或 fetch 发送请求
            const formData = new FormData();

            formData.append('files', file,file.name);

            try {
                // 模拟上传进度
                const simulateUploadProgress = (percent) => {
                    onProgress({ percent });
                };
                this.handleSimulate({onProgress, onSuccess, onError});
                uploadFiles(formData).then((res)=>{
                    //simulateUploadProgress(100);
                    if(res.status==200){
                        // 假设上传成功的响应
                        const response = { status: 'success' };
                        onSuccess(response, file);
                    }else{
                        onError(error);
                    }
                })

                // // 假设上传成功的响应
                // const response = { status: 'success' };
                // onSuccess(response, file);
            } catch (error) {
                onError(error);
            }
        },
        async handleSimulate({ onProgress, onSuccess, onError }) {
            try {
                // 模拟上传进度
                const simulateUploadProgress = (percent) => {
                    onProgress({ percent });
                };

                // 模拟上传的步骤
               //for (let i = 0; i <= 100; i += 50) {
                    await new Promise((resolve) => setTimeout(resolve, 50));
                    simulateUploadProgress(50);
                //}
                // // // 假设上传成功的响应
                // const response = { status: 'success' };
                // onSuccess(response, file);
            } catch (error) {
                onError(error);
            }
        },
        handleOutsideClick(event) {
            if (!this.$refs.drawerRef.contains(event.target)) {
                this.closeViewDialog();
            }
        },
		},
	})

</script>

<style lang="scss" scoped>

	.nav-link svg {
		margin-right: 5px;
		vertical-align: middle;
	}
	.nav-link span {
		vertical-align: middle;
	}
	.ant-menu-submenu-popup {
		width: 100%;
	}

</style>
