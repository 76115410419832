import { axios } from "@/utils/request";

// 获取漏洞数据列表
export function getVulnerabilitiesList(data) {
  return axios({
    url: `/v1/vuln/paginQuery`,
    method: "get",
    params: data,
  });
}

// 获取详情数据
export function getVulnerabilityDetail(data) {
  return axios({
    url: `/v1/vuln/detail`,
    method: "get",
    params: data
  });
}

// 获取受影响列表数据
export function getVulnerabilityDetailData(data) {
  return axios({
    url: `/vulnerabilityDatabase/artifactPage`,
    method: "get",
    params: data,
  });
}

export function feedsPaginQuery() {
  return axios({
    url: `/v1/vuln/feedsPaginQuery?page=1&size=50`,
    method: "get",
  });
}

export function uploadFiles(formData) {
  return axios({
    url: `/v1/vuln/upload-files`,
    method: "post",
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData
  });
}


export function downloadFile(fileName) {
  return axios({
    url: `/feeds/json/cve/1.1/`+fileName,
    method: "get",
    responseType: 'blob'
  });
}